import styled, { css } from 'styled-components'
import BgImage from '@images/works/jayne/gradient-bg.png'
import BgImageFlipped from '@images/works/jayne/gradient-bg-flipped.png'
import BgImage2 from '@images/works/jayne/gradient-bg2.png'
import BgImage2Flipped from '@images/works/jayne/gradient-bg2-flipped.png'

export const Wrapper = styled.section`
  padding: 10vw 0;

  ${({ theme }) => theme.media.m} {
    padding: 100px 0;
  }
`

export const Background = styled.div`
  position: absolute;
  width: 220vw;
  max-width: 2400px;
  aspect-ratio: 2 / 1.37;

  &::before,
  &::after {
    z-index: 0;
    content: '';
    position: absolute;
    left: 0;
    width: 300%;
    height: 100%;
    background: top left no-repeat;
    background-size: contain;
    animation: gradientAnimation 15s infinite linear;
    transform: translate3d(0, 0, 0);
  }

  &::before {
    top: 0;
    background-image: url(${({ centered }) => (centered ? BgImage : BgImage2)});
  }

  &::after {
    top: 80%;
    background-image: url(${({ centered }) =>
      centered ? BgImageFlipped : BgImage2Flipped});
  }

  @keyframes gradientAnimation {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(-66%, 0, 0);
    }
  }

  ${({ theme }) => theme.media.m} {
    width: 120vw;
    aspect-ratio: 2 / 1.37;
  }

  ${({ theme }) => theme.media.l} {
    &::before,
    &::after {
      width: 300%;
    }
  }
`

export const ContentFirst = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 2400px;
  aspect-ratio: 2 / 1.37;
  padding: 0 0 20px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    min-height: 400px;
    padding: 30px;
  }

  h3 {
    margin: 30px 0 20px;
    font-size: 32px;
  }

  p {
    line-height: 1.66;
  }

  ${({ theme }) => theme.media.l} {
    flex-direction: row;

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 50%;
      padding: 100px;
    }

    h3 {
      margin: 30px 0 20px;
      font-size: 38px;
    }
  }
`

export const ContentSecond = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 2400px;
  aspect-ratio: 2 / 1.37;
  padding: 0 0 20px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    min-height: 400px;
    padding: 30px;
  }

  h3 {
    margin: 30px 0 20px;
    font-size: 32px;
  }

  p {
    margin: 20px 0;
    line-height: 1.66;
  }

  ${({ theme }) => theme.media.l} {
    flex-direction: row;

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 50%;
      padding: 100px;
    }

    > div:nth-child(1) {
      order: 2;
    }

    h3 {
      margin: 30px 0 20px;
      font-size: 38px;
    }
  }
`

export const Product1 = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  > img {
    transition: all 0.6s ease;
    ${({ active }) =>
      active
        ? css`
            transform: translate3d(0, -50%, 0);
            opacity: 1;
          `
        : css`
            transform: translate3d(0, -40%, 0);
            opacity: 0;
          `}
  }

  > img:nth-child(1) {
    position: absolute;
    top: 60%;
    right: 15%;
    width: 100%;
    transition-delay: 0.3s;
  }

  > img:nth-child(2) {
    position: absolute;
    top: 50%;
    left: 15%;
    width: 100%;
    transition-delay: 0.5s;
  }

  ${({ theme }) => theme.media.l} {
    > img:nth-child(1) {
      width: 140%;
      top: 60%;
      right: 5%;
    }

    > img:nth-child(2) {
      width: 140%;
      left: 5%;
    }
  }
`

export const Product2 = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  > img {
    transition: all 0.6s ease;
    ${({ active }) =>
      active
        ? css`
            transform: translate3d(0, -50%, 0);
            opacity: 1;
          `
        : css`
            transform: translate3d(0, -40%, 0);
            opacity: 0;
          `}
  }

  > img:nth-child(1) {
    position: absolute;
    top: 55%;
    right: 12%;
    width: 75%;
    transition-delay: 0.5s;
  }

  > img:nth-child(2) {
    position: absolute;
    top: 50%;
    left: 12%;
    width: 100%;
    transition-delay: 0.3s;
  }

  ${({ theme }) => theme.media.l} {
    > img:nth-child(1) {
      width: 115%;
      right: -8%;
    }

    > img:nth-child(2) {
      width: 150%;
      left: -7%;
    }
  }
`

export const ImgText = styled.div`
  overflow: hidden;
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
`

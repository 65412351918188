import React, { useState } from 'react'
import { InView } from 'react-intersection-observer'
import ReactMarkdown from 'react-markdown'

import HorWrap from '@atoms/HorWrap'

import {
  Wrapper,
  Background,
  ContentFirst,
  ContentSecond,
  ProductThree,
  ProductFour,
  ImgText,
} from './styles.js'

import product3a from '@images/works/jayne/product1a.png'
import product3b from '@images/works/jayne/product1b.png'
import product3c from '@images/works/jayne/product2b.png'
import product3d from '@images/works/jayne/product3d.png'
import product3e from '@images/works/jayne/product3e.png'
import product4 from '@images/works/jayne/product4.png'
import productText3 from '@images/works/jayne/lorem-ipsum.png'

const GradientBackgroundSecond = ({ text }) => {
  const [sectionInView, setSectionInView] = useState(false)
  const [content1InView, setContent1InView] = useState(false)
  const [content2InView, setContent2InView] = useState(false)

  return (
    <InView
      onChange={(inView, entry) => inView && setSectionInView(true)}
      threshold={0.25}
    >
      <Wrapper active={sectionInView}>
        <Background />
        <HorWrap>
          <InView
            onChange={(inView, entry) => inView && setContent1InView(true)}
            threshold={0.25}
          >
            <ContentFirst active={content1InView}>
              <ProductThree active={content1InView}>
                <img src={product3a} />
                <img src={product3b} />
                <img src={product3c} />
                <img src={product3d} />
                <img src={product3e} />
              </ProductThree>
              {text && <ReactMarkdown children={text} />}
            </ContentFirst>
          </InView>
          <InView
            onChange={(inView, entry) => inView && setContent2InView(true)}
            threshold={0.25}
          >
            <ContentSecond active={content2InView}>
              <ProductFour active={content2InView}>
                <img src={product4} />
              </ProductFour>
            </ContentSecond>
          </InView>
        </HorWrap>
      </Wrapper>
    </InView>
  )
}

export default GradientBackgroundSecond

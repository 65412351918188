import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Main from '@templates/Main'

import HorWrap from '@atoms/HorWrap'
import PurpleHeader from '@atoms/PurpleHeader'

import GradientBackgroundFirst from '@layout/worksBlocks/Jayne/GradientBackgroundFirst'
import GradientBackgroundSecond from '@layout/worksBlocks/Jayne/GradientBackgroundSecond'
import Hero from '@layout/worksBlocks/Jayne/Hero'
import Credits from '@blocks/Credits'
import Next from '@blocks/Next'
import Spacer from '@blocks/Spacer'

const ProjectJayne = ({ data, transitionStatus }) => {
  const pageData = data.datoCmsProjectJayne
  const [startPage, setStartPage] = useState(false)

  useEffect(() => {
    setTimeout(() => setStartPage(true), 100)
  }, [setStartPage])

  return (
    <Main
      seo={pageData.seoMetaTags}
      enterColor={pageData.color.hex}
      transitionStatus={transitionStatus}
    >
      <Hero
        active={startPage && transitionStatus === 'entered'}
        path="jayne"
        data={{
          header: pageData.name,
        }}
      />
      <Spacer size="s" />
      <Credits data={pageData.credits[0]} />
      <Spacer size="m" />
      <GradientBackgroundFirst
        text1={pageData.product1}
        text2={pageData.product2}
      />
      <Spacer size="m" />
      <GradientBackgroundSecond text={pageData.product3} />
      <Next data={pageData.nextProject} />
    </Main>
  )
}

export const query = graphql`
  query ProjectJayneQuery {
    datoCmsProjectJayne {
      slug
      name
      seoMetaTags {
        tags
      }
      color {
        hex
      }
      credits {
        id
        header
        description
        credits
      }
      product1
      product2
      product3
      nextProject {
        ... on DatoCmsProject {
          id
          name
          slug
          color {
            hex
          }
        }
        ... on DatoCmsProjectSpotify {
          id
          name
          slug
          color {
            hex
          }
        }
        ... on DatoCmsProjectFroodie {
          id
          name
          slug
          color {
            hex
          }
        }
        ... on DatoCmsProjectOracle {
          id
          name
          slug
          color {
            hex
          }
        }
      }
    }
  }
`

export default ProjectJayne

import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0 50px;
  width: 100%;
  height: 500px;
  padding: 0;

  > div:nth-child(2) {
    z-index: 2;
  }

  ${({ theme }) => theme.media.xs} {
    margin: 0 0 90px;
    height: 700px;
    padding: 0;
  }

  ${({ theme }) => theme.media.m} {
    margin: 0 0 90px;
    height: 600px;
    padding: 0;

    &::after {
      z-index: 0;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: ${({ theme }) => theme.heroPaddings.m};
      height: 100%;
      background-color: white;
    }
  }

  ${({ theme }) => theme.media.l} {
    margin: 0 0 110px;
    padding: 0;
    height: 700px;

    &::after {
      width: ${({ theme }) => theme.heroPaddings.l};
    }
  }

  ${({ theme }) => theme.media.xl} {
    margin: 0 0 130px;
    padding: 0;
    height: 800px;
  }

  ${({ theme }) => theme.media.xxl} {
    margin: 0 0 150px;
    padding: 0;
    height: 1000px;
  }
`

export const Image = styled.img`
  transition: all 0.6s 3s ease;
  ${({ active }) =>
    active
      ? css`
          transform: translate3d(0, 0, 0);
          opacity: 1;
        `
      : css`
          transform: translate3d(0, 10%, 0);
          opacity: 0;
        `}
`

export const Video = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  max-width: 2150px;
  height: 550px;
  opacity: ${({ active }) => (active ? '1' : '0')};
  transition: opacity 2s 2s ease;

  &::after {
    z-index: -1;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 95%;
    background-color: #fcdbcd;
  }

  > div:first-child {
    position: relative;
    // overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .react-parallax {
    overflow: visible !important;
    position: absolute !important;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100%;
  }

  .react-parallax-background-children {
    height: 100%;
  }

  .react-parallax-background {
    width: 100vw;
    height: 100%;
  }

  img {
    position: absolute;
    bottom: -60px;
    width: 250%;
  }

  iframe {
    width: 100%;
    height: 150%;
    transform: translateY(-10%);
  }

  iframe {
    &:nth-child(2) {
      display: none;
    }
  }

  ${({ theme }) => theme.media.xs} {
    height: 700px;
  }

  @media (min-width: 500px) {
    height: 750px;

    iframe {
      width: 100%;
      height: 170%;
      transform: translateY(-10%);
    }
  }

  @media (min-width: 600px) {
    iframe {
      width: 100%;
      height: 220%;
    }
  }

  ${({ theme }) => theme.media.s} {
    iframe {
      &:nth-child(1) {
        display: none;
      }

      &:nth-child(2) {
        display: block;
      }
    }

    iframe {
      width: 150%;
      height: 100%;
      transform: translateY(0);
      transform: translateX(-25%);
    }
  }

  ${({ theme }) => theme.media.m} {
    width: calc(100vw - ${({ theme }) => theme.heroPaddings.m});
    height: 600px;

    .react-parallax {
      width: calc(100vw - ${({ theme }) => theme.heroPaddings.m});
      height: 100%;
    }

    .react-parallax-background {
      width: calc(100vw - ${({ theme }) => theme.heroPaddings.m});
    }

    img {
      width: 100%;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: -30px;
      width: 2px;
      background: black;
    }

    iframe {
      width: 120%;
      height: 100%;
      transform: translateX(-10%);
    }
  }

  ${({ theme }) => theme.media.l} {
    height: 700px;
    width: calc(100vw - ${({ theme }) => theme.heroPaddings.l});

    .react-parallax {
      width: calc(100vw - ${({ theme }) => theme.heroPaddings.l});
    }

    .react-parallax-background {
      width: calc(100vw - ${({ theme }) => theme.heroPaddings.l});
    }
  }

  ${({ theme }) => theme.media.xl} {
    height: 800px;

    iframe {
      width: 120%;
      height: 120%;
      transform: translateX(-10%);
    }
  }

  ${({ theme }) => theme.media.xxl} {
    height: 1000px;

    iframe {
      width: 130%;
      height: 120%;
      transform: translateX(-15%);
    }
  }

  @media (min-width: 2200px) {
    iframe {
      width: 130%;
      height: 140%;
      transform: translateX(-15%);
    }
  }
`

export const VideoCurtain = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 40px);

  > div {
    overflow: hidden;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${({ active }) => (active ? '100%' : '0')};
    transition: height 0.8s 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    pointer-events: none;
  }

  > div:nth-child(1) {
    height: 100%;
    background: white;
    opacity: ${({ active }) => (active ? '0' : '1')};
    transition: opacity 0.8s 2s ease;
  }

  > div:nth-child(2) {
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 50%;
      background: white;
      transition: transform 0.8s 2.6s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &::before {
      bottom: 50%;
      border-bottom: 2px solid #f8dacb;
      transform: translate3d(0, ${({ active }) => (active ? '-100%' : '0')}, 0);
    }

    &::after {
      top: calc(50% - 3px);
      border-top: 2px solid #f8dacb;
      transform: translate3d(0, ${({ active }) => (active ? '120%' : '0')}, 0);
    }
  }

  > div:nth-child(3) {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: -30px;
      width: 50%;
      background: white;
      transition: transform 0.8s 1.6s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &::before {
      right: 50%;
      border-right: 2px solid #f8dacb;
      transform: translate3d(${({ active }) => (active ? '-99%' : '0')}, 0, 0);
    }

    &::after {
      left: calc(50% - 3px);
      border-left: 2px solid #f8dacb;
      transform: translate3d(${({ active }) => (active ? '103%' : '0')}, 0, 0);
    }
  }
`

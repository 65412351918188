import React, { useState } from 'react'
import { InView } from 'react-intersection-observer'
import ReactMarkdown from 'react-markdown'

import HorWrap from '@atoms/HorWrap'

import {
  Wrapper,
  Background,
  ContentFirst,
  ContentSecond,
  Product1,
  Product2,
  ImgText,
} from './styles.js'

import productImage1a from '@images/works/jayne/product1a.png'
import productImage1b from '@images/works/jayne/product1b.png'
import productText1 from '@images/works/jayne/ease+balance.png'
import productImage2a from '@images/works/jayne/product2a.png'
import productImage2b from '@images/works/jayne/product2b.png'
import productText2 from '@images/works/jayne/cbd-vape.png'

const GradientBackgroundFirst = ({ text1, text2 }) => {
  const [sectionInView, setSectionInView] = useState(false)
  const [content1InView, setContent1InView] = useState(false)
  const [content2InView, setContent2InView] = useState(false)

  return (
    <InView
      onChange={(inView, entry) => inView && setSectionInView(true)}
      threshold={0.25}
    >
      <Wrapper active={sectionInView}>
        <Background />
        <HorWrap>
          <InView
            onChange={(inView, entry) => inView && setContent1InView(true)}
            threshold={0.25}
          >
            <ContentFirst active={content1InView} first>
              <div>
                <Product1 active={content1InView}>
                  <img src={productImage1a} />
                  <img src={productImage1b} />
                </Product1>
              </div>
              <div>
                <ImgText>
                  <img src={productText1} />
                </ImgText>
                {text1 && <ReactMarkdown children={text1} />}
              </div>
            </ContentFirst>
          </InView>
          <InView
            onChange={(inView, entry) => inView && setContent2InView(true)}
            threshold={0.25}
          >
            <ContentSecond active={content2InView}>
              <div>
                <Product2 active={content2InView}>
                  <img src={productImage2a} />
                  <img src={productImage2b} />
                </Product2>
              </div>
              <div>
                <ImgText>
                  <img src={productText2} />
                </ImgText>
                {text2 && <ReactMarkdown children={text2} />}
              </div>
            </ContentSecond>
          </InView>
        </HorWrap>
      </Wrapper>
    </InView>
  )
}

export default GradientBackgroundFirst

import styled, { css } from 'styled-components'
import BgImage from '@images/works/jayne/gradient-bg.png'
import BgImageFlipped from '@images/works/jayne/gradient-bg-flipped.png'
import BgImage2 from '@images/works/jayne/gradient-bg2.png'
import BgImage2Flipped from '@images/works/jayne/gradient-bg2-flipped.png'

export const Wrapper = styled.section`
  padding: 10vw 0;
`

export const Background = styled.div`
  position: absolute;
  width: 200vw;
  max-width: 2400px;
  aspect-ratio: 2 / 1.37;

  &::before,
  &::after {
    z-index: 0;
    content: '';
    position: absolute;
    left: 0;
    width: 300%;
    height: 100%;
    background: top left no-repeat;
    background-size: contain;
    animation: gradientAnimation 15s infinite linear;
    transform: translate3d(0, 0, 0);
  }

  &::before {
    top: 0;
    background-image: url(${({ centered }) => (centered ? BgImage : BgImage2)});
  }

  &::after {
    top: 80%;
    background-image: url(${({ centered }) =>
      centered ? BgImageFlipped : BgImage2Flipped});
  }

  @keyframes gradientAnimation {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(-66%, 0, 0);
    }
  }

  ${({ theme }) => theme.media.m} {
  }

  ${({ theme }) => theme.media.l} {
    width: 120vw;

    &::before,
    &::after {
      width: 300%;
    }
  }
`

export const ContentFirst = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 2400px;
  aspect-ratio: 2 / 1.37;
  padding: 0 0 20px;

  h3 {
    margin: 30px 0 20px;
    font-size: 32px;
  }

  p {
    margin: 20px 0;
    line-height: 1.66;
  }

  ${({ theme }) => theme.media.l} {
    h3 {
      margin: 30px 0 20px;
      font-size: 38px;
    }
  }
`

export const ContentSecond = styled.section``

export const ProductThree = styled.div`
  position: relative;
  margin: 0 0 60px;
  width: 100%;
  aspect-ratio: 2/ 1;

  > img {
    position: absolute;
    top: 50%;
    transition: all 0.6s ease;
    ${({ active }) =>
      active
        ? css`
            transform: translate3d(0, -50%, 0);
            opacity: 1;
          `
        : css`
            transform: translate3d(0, -40%, 0);
            opacity: 0;
          `}
  }

  > img:nth-child(1) {
    left: -5%;
    width: 45%;
    transition-delay: 0.3s;
  }

  > img:nth-child(2) {
    top: 45%;
    left: 10%;
    width: 45%;
    transition-delay: 0.5s;
  }

  > img:nth-child(3) {
    top: 45%;
    left: 33%;
    width: 50%;
    transition-delay: 0.7s;
  }

  > img:nth-child(4) {
    top: 55%;
    left: 53%;
    width: 53%;
    transition-delay: 0.9s;
  }

  > img:nth-child(5) {
    left: 70%;
    width: 35%;
    transition-delay: 1.1s;
  }
`

export const ProductFour = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 2/ 1;

  img {
    width: 100%;
    height: auto;
    transition: all 0.6s ease;
    ${({ active }) =>
      active
        ? css`
            transform: translate3d(0, 0, 0);
            opacity: 1;
          `
        : css`
            transform: translate3d(0, 10%, 0);
            opacity: 0;
          `}
  }
`

export const ImgText = styled.div`
  overflow: hidden;
  width: 100%;
  text-align: center;

  img {
    margin: 180px auto 20px;
    width: 100%;
    height: auto;
  }

  ${({ theme }) => theme.media.m} {
    img {
      margin: 220px auto 40px;
      width: auto;
      height: auto;
    }
  }
`
